@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

/* ROW START */
.row-start-start {
  @apply flex flex-row justify-start items-start;
}
.row-start-center {
  @apply flex flex-row justify-start items-center;
}
.row-start-end {
  @apply flex flex-row justify-start items-end;
}
.row-start-stretch {
  @apply flex flex-row justify-start items-stretch;
}
/* ROW END */
.row-end-start {
  @apply flex flex-row justify-end items-start;
}
.row-end-center {
  @apply flex flex-row justify-end items-center;
}
.row-end-end {
  @apply flex flex-row justify-end items-end;
}
.row-end-stretch {
  @apply flex flex-row justify-end items-stretch;
}
/* ROW CENTER */
.row-center-start {
  @apply flex flex-row justify-center items-start;
}
.row-center-center {
  @apply flex flex-row justify-center items-center;
}
.row-center-end {
  @apply flex flex-row justify-center items-end;
}
.row-center-stretch {
  @apply flex flex-row justify-center items-stretch;
}
/* ROW AROUND */
.row-around-start {
  @apply flex flex-row justify-around items-start;
}
.row-around-center {
  @apply flex flex-row justify-around items-center;
}
.row-around-end {
  @apply flex flex-row justify-around items-end;
}
.row-around-stretch {
  @apply flex flex-row justify-around items-stretch;
}
/* ROW BETWEEN */
.row-between-start {
  @apply flex flex-row justify-between items-start;
}
.row-between-center {
  @apply flex flex-row justify-between items-center;
}
.row-between-end {
  @apply flex flex-row justify-between items-end;
}
.row-between-stretch {
  @apply flex flex-row justify-between items-stretch;
}

/* COL START */
.col-start-start {
  @apply flex flex-col justify-start items-start;
}
.col-start-center {
  @apply flex flex-col justify-start items-center;
}
.col-start-end {
  @apply flex flex-col justify-start items-end;
}
.col-start-stretch {
  @apply flex flex-col justify-start items-stretch;
}
/* COL END */
.col-end-start {
  @apply flex flex-col justify-end items-start;
}
.col-end-center {
  @apply flex flex-col justify-end items-center;
}
.col-end-end {
  @apply flex flex-col justify-end items-end;
}
.col-end-stretch {
  @apply flex flex-col justify-end items-stretch;
}
/* COL CENTER */
.col-center-start {
  @apply flex flex-col justify-center items-start;
}
.col-center-center {
  @apply flex flex-col justify-center items-center;
}
.col-center-end {
  @apply flex flex-col justify-center items-end;
}
.col-center-stretch {
  @apply flex flex-col justify-center items-stretch;
}
/* COL AROUND */
.col-around-start {
  @apply flex flex-col justify-around items-start;
}
.col-around-center {
  @apply flex flex-col justify-around items-center;
}
.col-around-end {
  @apply flex flex-col justify-around items-end;
}
.col-around-stretch {
  @apply flex flex-col justify-around items-stretch;
}
/* COL BETWEEN */
.col-between-start {
  @apply flex flex-col justify-between items-start;
}
.col-between-center {
  @apply flex flex-col justify-between items-center;
}
.col-between-end {
  @apply flex flex-col justify-between items-end;
}
.col-between-stretch {
  @apply flex flex-col justify-between items-stretch;
}

.wrap {
  @apply flex-wrap;
}

.grow-1 {
  @apply grow;
}

.w-100 {
  @apply w-full;
}

.h-100 {
  @apply h-full;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.p-inputnumber-button-up {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 24px;
}

.p-inputnumber-button-down {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 24px;
}

.p-button-icon-only {
  height: 42px !important;
  width: 42px !important;
}

.p-splitbutton-menubutton {
  height: 40px !important;
}
