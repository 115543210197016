.wrapper {
  color: var(--color-bg-0);
  min-height: 100vh;
  color: var(--color-text);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* opacity: 0.5; */
}

.subtitle {
  font-size: var(--font-size-big);
  font-weight: var(--font-weight-semibold);
}

.subtitle2 {
  font-size: var(--font-size-small-1);
  font-weight: var(--font-weight-semibold);
  color: var(--color-text-light-2);
}

.registerButton {
  min-height: 3rem;
}
.floatingContainer {
  background-color: white;
  box-shadow: 0 0.2rem 0.625rem 0 rgba(76, 78, 100, 0.5);
}

.loginSection {
  width: 420px;
  padding-left: 60px;
  padding-right: 60px;
}

.wrapper input {
  width: 100%;
}

.wrapper label {
  color: var(--color-text-light-1);
  font-size: var(--font-size-small-1);
}

.wrapper .errorInput {
  background-color: var(--color-error-light);
}

.wrapper .errorInput input {
  background-color: var(--color-error-light);
}

.uploadHeader {
  height: 3.2rem;
}

.uploadHeader > span,
.uploadHeader > button {
  width: 2.2rem !important;
  height: 2.2rem !important;
}

.passwordInput {
  width: 100%;
}

.passwordInput input {
  width: 100%;
}

.passwordInput i {
  margin-top: -0.85rem !important;
}

.radioButton label {
  color: var(--color-text);
  font-size: var(--font-size-normal);
}

.browseButtonContainer {
  background-color: var(--color-primary-light);
  border-radius: 6px;
}

.browseButton {
  border: 1px solid #ced4da !important;
}

.browseButton div {
  color: var(--color-text);
}

.browseButton i {
  color: var(--color-text);
}
