.customInputSwitch[aria-checked="true"] > span {
  background: #dc2626 !important;
}

.customTable td {
  padding-top: 0.5rem !important;
  padding-bottom: 0.4rem !important;
}

/* .customTable > div {
  max-height: 26.5rem;
} */
