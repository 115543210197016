.wrapper {
}

.inputContainer {
  position: relative;
  border-style: dashed;
  border-color: var(--color-border);
  border-radius: var(--border-radius-0);
  border-width: 1px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  /* padding: 5px; */
}

.overlay {
  top: 0;
  left: 0;
  width: 95%;
  height: 95%;
  background-color: rgba(
    0,
    0,
    0,
    0.1
  ); /* Black overlay with 50% transparency */
  z-index: 1;
}

.inputContainer img {
  position: absolute;
  z-index: 10;
}

.inputContainerButton {
  position: absolute;
  z-index: 20;
}

.inputContainerButtonFilled {
  position: absolute;
  z-index: 20;
  opacity: 0;
}

.inputContainer:hover .inputContainerButtonFilled {
  opacity: 0.8;
}

.dialogHeader {
}

.dialogContent {
  background-color: var(--color-bg-0);
  border-style: solid;
  border-radius: var(--border-radius-0);
  border-width: 1px;
  border-color: var(--color-border);
  position: relative;
}
