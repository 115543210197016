.wrapper {
  height: var(--height-nav-bar);
  background-color: var(--color-white);
  box-shadow: 0 0.125rem 0.625rem 0 rgba(76, 78, 100, 0.22);
  z-index: 80;
}

.hamburgerButton {
  /* background-color: red; */
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.title {
  font-size: var(--font-size-normal-2);
}

.customDropDown > span {
  font-size: 1rem;
  font-weight: 500;
}
