.wrapper {
}

.wrapper input {
  width: 100%;
}

.wrapper label {
  color: var(--color-text-light-1);
  font-size: var(--font-size-small-1);
}

.wrapper .errorInput {
  background-color: var(--color-error-light);
}

.passwordInput {
  width: 100%;
}

.passwordInput input {
  width: 100%;
}

.passwordInput i {
  margin-top: -0.85rem !important;
}

.radioButton label {
  color: var(--color-text);
  font-size: var(--font-size-normal);
}
