.wrapper {
  cursor: pointer;
  margin: 0;
  padding: 0;
  color: var(--color-text);
  list-style-type: none;
  border-radius: var(--border-radius-0);
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}

.wrapper:hover {
  background-color: var(--color-bg-2);
}

.wrapperActive {
  cursor: pointer;
  margin: 0;
  padding: 0;
  list-style-type: none;
  color: var(--color-text);
  border-radius: var(--border-radius-0);
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
  background-color: var(--color-bg-2);
  padding-bottom: 0.1rem;
  /* padding-bottom: 0.4rem; */
}

.headerContainer {
  cursor: pointer;
  height: 2.1875rem;
}

.iconContainer {
  width: 1.5625rem;
}

.childContainer {
  /* animation: show-animation-translate 0.5s ease-in-out forwards; */
  overflow-y: auto;
}

/* @keyframes show-animation-translate {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
} */
