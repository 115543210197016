.p-inputtext{
    padding: 0;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    padding-top: 0.4375rem;
    padding-bottom: 0.4375rem;
}

.p-button{
    padding: 0;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    height: 2.5rem;
}

.p-button span{
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-small-1);
}

th[role="columnheader"] {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}

td[role="cell"] {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}